import React from 'react';
import './App.css';
import gileadIcon from './icons/Gilead_Logo_1C.png';
import MigrationLog from './components/systeminv/MigrationLog';
import ErrorBoundary from './ErrorBoundary';
import Appauth from './auth/appAuth';

function App() {
  const clientIdForSignUp = `${process.env.REACT_APP_DOMAIN_URL}/oauth2/authorize?client_id=${process.env.REACT_APP_USER_CLIENT_ID}&response_type=token&IDPProvider=GileadSSOSignIn&scope=email+openid+profile&redirect_uri=${process.env.REACT_APP_CLOUD_FRONT_URL}`;

  return (
    <div className='App'>
      <div className='logo_div'>
        {' '}
        <img className='logo' src={gileadIcon} />
      </div>
      <div>
        <ErrorBoundary>
          <Appauth />
          <MigrationLog redirectUrl={clientIdForSignUp} />
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default App;
