import axios from 'axios';
import { MigrationLog } from './migrationLogProps';

const clientIdForSignUp = `${process.env.REACT_APP_DOMAIN_URL}/oauth2/authorize?client_id=${process.env.REACT_APP_USER_CLIENT_ID}&response_type=token&IDPProvider=GileadSSOSignIn&scope=email+openid+profile&redirect_uri=${process.env.REACT_APP_CLOUD_FRONT_URL}`;
export function createMigrationLog(postObject: MigrationLog): Promise<any[]> {
  console.log(postObject);
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_MIGRATION_TRACKER_ENDPOINT}/createMigrationTracker`,
        postObject,
        {
          headers: {
            authorization: localStorage.getItem('accesstoken'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (Number(localStorage.getItem('expires_at')) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export function updateMigrationLog(postObject: MigrationLog): Promise<any[]> {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_MIGRATION_TRACKER_ENDPOINT}/updateMigrationTracker`,
        postObject,
        {
          headers: {
            authorization: localStorage.getItem('accesstoken'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (Number(localStorage.getItem('expires_at')) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}
