import React, { useState, useEffect } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import {
  getSystemsByNameToken,
  getEmployeesByToken,
} from '../services/getServicesPromise.js';
import { EmployeeForm } from '../services/migrationLogProps';
import { migrationStatus, yesNo, priorityLevels } from '../staticOptions';
import { radioGroupValidator } from '../validators';
import { LabelSpec } from '../LabelSpec';
import './MigrationLogs.css';
import './MigrationLogForm.css';
import {
  FormDatePicker,
  FormInput,
  FormTextArea,
  FormDropDownList,
  FormAutoComplete,
  FormRadioGroup,
} from './formComponents';

// used for preventing double click button
let loading = false;

const MigrationLogForm = (props: any) => {
  const NameValidationMessage =
    'Start typing a System Name and select one from the menu.';
  const ManagerValidationMessage =
    "Start typing an employee's name and select one from the menu.";
  const OperationStatusalidationMessage = 'Please enter the Status';
  const urlRegex = new RegExp(
    '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
  );

  const [formItem, setFormItem] = useState({
    Priority: priorityLevels[0],
    ...props.item,
  });
  const [filteredSystemsInventoryData, setFilteredSystemInventoryData] =
    useState([]);

  const [filteredSystemManagerData, setFilteredSystemManagerData] = useState(
    [] as EmployeeForm[]
  );
  const [sysManagerSearch, setSysManagerSearch] = useState('');
  const [systemManagerLoading, setSystemManagerLoading] = useState(false);
  const [systemManagerValid, setSystemManagerValid] = useState(false);

  const [filteredMigrationOwnerData, setFilteredMigrationOwnerData] = useState(
    [] as EmployeeForm[]
  );
  const [migrationOwnerSearch, setMigrationOwnerSearch] = useState('');
  const [migrationOwnerLoading, setMigrationOwnerLoading] = useState(false);
  const [migrationOwnerValid, setMigrationOwnerValid] = useState(false);

  const [systemSearch, setSystemSearch] = useState('');
  const [systemValid, setSystemValid] = useState(false);
  const [systemLoading, setSystemLoading] = useState(false);

  useEffect(() => {
    console.log('props.item:', props.item);
    console.log('SUGABOP', props.readonly);
    if (props.mode === 'edit') {
      // setFormItem(props.item);
      setSystemValid(true);
      setSystemManagerValid(true);
      setMigrationOwnerValid(true);
    }
  }, []);

  const systemValidator = (value: any) =>
    !systemValid ? NameValidationMessage : '';
  const systemManagerValidator = (value: any) =>
    !systemManagerValid ? ManagerValidationMessage : '';

  const migrationOwnerValidator = (value: any) =>
    !migrationOwnerValid ? ManagerValidationMessage : '';

  const numberValidator = (value: any) => {
    const numberRegex = new RegExp(/^[0-9]*$/);
    const validNum = numberRegex.test(value);
    return value !== undefined && (!validNum || value.length > 9)
      ? 'Input must be a number with 9 digits or less'
      : '';
  };

  useEffect(() => {
    if (systemSearch.length > 2) {
      console.log(systemSearch.length, systemSearch);
      const timer = setTimeout(async () => {
        setSystemLoading(true);
        // let filteredProtocols = filterData(protocolSearch)
        try {
          const systems = await getSystemsByNameToken(systemSearch);
          setFilteredSystemInventoryData(systems);
          setSystemLoading(false);
        } catch (err) {
          alert('Fetching Systems Inventory data failed - 500x');
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [systemSearch]);

  useEffect(() => {
    if (sysManagerSearch.length > 2) {
      console.log(sysManagerSearch.length, sysManagerSearch);
      const timer = setTimeout(async () => {
        setSystemManagerLoading(true);
        const employees = await getEmployeesByToken(sysManagerSearch);
        setFilteredSystemManagerData(employees);
        setSystemManagerLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [sysManagerSearch]);

  useEffect(() => {
    if (migrationOwnerSearch.length > 2) {
      console.log(migrationOwnerSearch.length, migrationOwnerSearch);
      const timer = setTimeout(async () => {
        setMigrationOwnerLoading(true);
        const employees = await getEmployeesByToken(migrationOwnerSearch);
        setFilteredMigrationOwnerData(employees);
        setMigrationOwnerLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [migrationOwnerSearch]);

  const onSystemChange = async (event: any, frp: any) => {
    console.log('ON SYSTEM CHANGE', event);
    const systemNameSearch = event.target.value;
    // if keyboard input => self, if menu selection => get selected object
    const sysVal =
      systemNameSearch && systemNameSearch.hasOwnProperty('Name')
        ? systemNameSearch.Name
        : systemNameSearch;
    if (sysVal) {
      console.log(sysVal);
      const filteredCompData = event.target.props.data;
      const eventType = event.nativeEvent.type;
      const valueSelected =
        eventType === 'click' ||
        (eventType === 'keydown' && event.nativeEvent.keyCode === 13);
      if (valueSelected) {
        const getMatch = filteredCompData.find((f: any) => f.Name === sysVal);
        if (getMatch) {
          console.log('We have a match:', getMatch);
          frp.onChange('BusinessFunction', {
            value: getMatch.addBusinessFunctions,
          });
          frp.onChange('SystemOwner', {
            value: getMatch.SystemManager,
          });
          frp.onChange('SystemID', { value: getMatch.ID });
          setSystemValid(true);
          if (
            getMatch.SystemManager != null &&
            getMatch.SystemManager.length > 0
          )
            setSystemManagerValid(true);
        }
      } else if (systemNameSearch.length > 2) {
        setSystemSearch(systemNameSearch);
        setSystemValid(false);
      }
    } else {
      setSystemValid(false);
    }
  };

  const onSystemManagerChange = async (event: any, frp: any) => {
    console.log('ON SYSTEM Manager Change', event);
    const systemManagerSearch = event.target.value;
    // if keyboard input => self, if menu selection => get selected object
    const sysManVal =
      systemManagerSearch && systemManagerSearch.hasOwnProperty('FullName')
        ? systemManagerSearch.FullName
        : systemManagerSearch;
    if (sysManVal) {
      console.log(sysManVal);
      const filteredCompData = event.target.props.data;
      const eventType = event.nativeEvent.type;
      const valueSelected =
        eventType === 'click' ||
        (eventType === 'keydown' && event.nativeEvent.keyCode === 13);
      if (valueSelected) {
        const getMatch = filteredCompData.find(
          (f: any) => f.FullName === sysManVal
        );
        if (getMatch) {
          console.log('We have a match.');
          setSystemManagerValid(true);
        }
      } else if (systemManagerSearch.length > 2) {
        setSysManagerSearch(systemManagerSearch);
        setSystemManagerValid(false);
      }
    } else {
      setSystemManagerValid(false);
    }
  };

  const onMigrationOwnerChange = async (event: any, frp: any) => {
    console.log('ON SYSTEM Manager Change', event);
    const migrationOwnerSearch = event.target.value;
    // if keyboard input => self, if menu selection => get selected object
    const migOwnerVal =
      migrationOwnerSearch && migrationOwnerSearch.hasOwnProperty('FullName')
        ? migrationOwnerSearch.FullName
        : migrationOwnerSearch;
    if (migOwnerVal) {
      console.log(migOwnerVal);
      const filteredCompData = event.target.props.data;
      const eventType = event.nativeEvent.type;
      const valueSelected =
        eventType === 'click' ||
        (eventType === 'keydown' && event.nativeEvent.keyCode === 13);
      if (valueSelected) {
        const getMatch = filteredCompData.find(
          (f: any) => f.FullName === migOwnerVal
        );
        if (getMatch) {
          console.log('We have a match.');
          setMigrationOwnerValid(true);
        }
      } else if (migrationOwnerSearch.length > 2) {
        setMigrationOwnerSearch(migrationOwnerSearch);
        setMigrationOwnerValid(false);
      }
    } else {
      setMigrationOwnerValid(false);
    }
  };

  return (
    <Dialog title='Migration Form' onClose={props.cancelEdit} height='90%'>
      <Form
        onSubmit={props.onSubmit}
        // initialValues={{Priority: priorityLevels[0]}}
        initialValues={formItem}
        render={(formRenderProps) => (
          <FormElement>
            {/* <fieldset className={"k-form-fieldset "} disabled={props.item.readonly}> */}
            <fieldset className='k-form-fieldset '>
              <div>
                {props.mode === 'edit' && (
                  <>
                    <h3>
                      Migration - ID
                      {formItem.MigrationID}
                    </h3>
                    {props.item.readonly && (
                      <h3>
                        <em>readonly</em>
                      </h3>
                    )}
                  </>
                )}
                <div className='tileContainer'>
                  <div className='tile'>
                    <legend className='k-form-legend'>
                      Migration Information
                    </legend>
                    <span>
                      <Field
                        name='SystemName'
                        component={FormAutoComplete}
                        label={<LabelSpec req>System Name</LabelSpec>}
                        placeholder='Please enter the System Name.'
                        required
                        validator={systemValidator}
                        data={filteredSystemsInventoryData}
                        textField='Name'
                        onChange={(e) => {
                          onSystemChange(e, formRenderProps);
                        }}
                        loading={systemLoading}
                        // readonly={props.item.readonly}
                        disabled={props.item.readonly}
                      />
                      <Field
                        name='BusinessFunction'
                        component={FormInput}
                        label='Business Function 🔒'
                        readOnly
                        disabled={props.item.readonly}
                      />
                    </span>
                    <Field
                      name='SystemOwner'
                      component={FormAutoComplete}
                      label={<LabelSpec req>System Manager</LabelSpec>}
                      placeholder='Please enter the System Owner.'
                      data={filteredSystemManagerData}
                      textField='FullName'
                      onChange={(e) => {
                        onSystemManagerChange(e, formRenderProps);
                      }}
                      loading={systemManagerLoading}
                      validator={systemManagerValidator}
                      disabled={props.item.readonly}
                    />
                    <Field
                      name='MigrationOwner'
                      component={FormAutoComplete}
                      label={<LabelSpec req>Migration Owner</LabelSpec>}
                      placeholder='Please enter the Migration Owner.'
                      data={filteredMigrationOwnerData}
                      textField='FullName'
                      onChange={(e) => {
                        onMigrationOwnerChange(e, formRenderProps);
                      }}
                      loading={migrationOwnerLoading}
                      validator={migrationOwnerValidator}
                      disabled={props.item.readonly}
                    />
                    {props.mode === 'edit' && (
                      <Field
                        name='Status'
                        placeholder='Please enter the Migration Status.'
                        validationMessage={OperationStatusalidationMessage}
                        component={FormDropDownList}
                        label='Status'
                        data={migrationStatus}
                        disabled={props.item.readonly}
                        // disabled={props.item.readonly}
                      />
                    )}
                    <Field
                      name='Priority'
                      placeholder='Please enter the Migration Priority.'
                      component={FormDropDownList}
                      label='Priority'
                      data={priorityLevels}
                      textField='label'
                      disabled={props.item.readonly}
                    />
                  </div>
                  <div className='tile'>
                    <legend className='k-form-legend'> Platform </legend>
                    <span style={{ textAlign: 'left' }}>
                      <Field
                        id='isSNP'
                        name='SystemsAndProcess'
                        label={<LabelSpec req>Is Systems & Process</LabelSpec>}
                        layout='horizontal'
                        component={FormRadioGroup}
                        data={yesNo}
                        validator={radioGroupValidator}
                        disabled={props.item.readonly}
                      />
                      <Field
                        name='OldPlatform'
                        component={FormInput}
                        label='Old Platform'
                        placeholder='Please enter the old platform (source)'
                        readOnly={props.item.readonly}
                        disabled={props.item.readonly}
                      />
                      <Field
                        name='NewPlatform'
                        component={FormInput}
                        label='New Platform'
                        placeholder='Please enter the new platform (destination)'
                        readOnly={props.item.readonly}
                        disabled={props.item.readonly}
                      />
                      <Field
                        name='MigrationTargetDate'
                        component={FormDatePicker}
                        format='MM/dd/yyyy'
                        label='Migration Target Date'
                        disabled={props.item.readonly}
                      />
                    </span>
                  </div>
                </div>

                <div className='rowContainer'>
                  <div className='textarea-comments'>
                    <Field
                      name='SparcUrl'
                      component={FormInput}
                      label='SPARC Url'
                      readOnly={props.item.readonly}
                    />
                    <Field
                      name='AzureWorkItemID'
                      suggest={0}
                      component={FormInput}
                      label='Azure Work Item ID'
                      validator={numberValidator}
                      readOnly={props.item.readonly}
                    />
                    <Field
                      name='Comments'
                      component={FormTextArea}
                      label={<LabelSpec>Comments</LabelSpec>}
                      rows={3}
                      readOnly={props.item.readonly}
                    />
                  </div>
                </div>

                {props.mode === 'edit' && (
                  <>
                    <div>
                      <span>
                        Created By{' '}
                        {formItem.CreatedBy ? formItem.CreatedBy : 'Unknown'} on{' '}
                        {new Date(formItem.CreatedOn).toLocaleDateString()}
                      </span>
                    </div>
                    {formItem.ModifiedOn != null && (
                      <div>
                        <span>
                          Last Modified By{' '}
                          {formItem.ModifiedBy
                            ? formItem.ModifiedBy
                            : 'Unknown'}{' '}
                          on{' '}
                          {new Date(formItem.ModifiedOn).toLocaleDateString()}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className='k-form-buttons' style={{ float: 'right' }}>
                {!props.item.readonly && (
                  <button
                    type='submit'
                    className='k-button k-primary'
                    disabled={!formRenderProps.allowSubmit}
                    onClick={() => {
                      if (formRenderProps.valid) {
                        const buttonDOM =
                          document.querySelectorAll<HTMLElement>(
                            '.k-form-buttons'
                          )[0];
                        buttonDOM.style.display = 'none';
                        loading = true;
                      }
                    }}
                  >
                    {' '}
                    {props.mode === 'new' ? 'Submit' : 'Update'}
                  </button>
                )}
                <button
                  type='submit'
                  className='k-button'
                  onClick={props.cancelEdit}
                >
                  {props.item.readonly ? 'Close' : 'Cancel'}
                </button>
              </div>
            </fieldset>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default MigrationLogForm;
