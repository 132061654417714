export const migrationStatus = [
  'New/Evaluating',
  'Pending Approval',
  'Approved',
  'DevComplete',
  'UAT',
  'Complete',
  'Decommissioned',
];
export const yesNo = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
export const departmentTypes = [
  'Biometrics',
  'Clinops',
  'PPM',
  'PSMO',
  'Regulatory',
];
export const approvalLevels = ['Fair', 'IRC', 'S&P'];
export const priorityLevels = [
  { label: 'Low', value: 1 },
  { label: 'Medium', value: 2 },
  { label: 'High', value: 3 },
  { label: 'Critical', value: 4 },
];

// data value -> object
export const findObjectByValue = (ds, key, value) =>
  ds.find((o) => o[key] === value);
