import axios from 'axios';
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
const clientIdForSignUp = `${process.env.REACT_APP_DOMAIN_URL}/oauth2/authorize?client_id=${process.env.REACT_APP_USER_CLIENT_ID}&response_type=token&IDPProvider=GileadSSOSignIn&scope=email+openid+profile&redirect_uri=${process.env.REACT_APP_CLOUD_FRONT_URL}`;
/** System Inventory  */
const filteredEmployeeResults = `${process.env.REACT_APP_DEV_URL}:8899/GetAutoActiveEmployees`;
const systemsInventoryByName = `${process.env.REACT_APP_PROD_URL}:8082/api/getSystemsInventoryByName/`;

const getEmployeesByTokenURL =
  'https://fcclowebprdn01.na.gilead.com:7085/api/getUserByNameToken/';

export default async function lookupEmployee(name) {
  return new Promise((resolve, reject) => {
    fetch(filteredEmployeeResults + name)
      .then((response) => response.json())
      .then((response) => resolve(response.recordsets[0]))
      .catch((error) => reject(error));
  });
}

export async function getSystemsByNameToken(name) {
  return new Promise((resolve, reject) => {
    fetch(systemsInventoryByName + name)
      .then((response) => response.json())
      .then((response) => resolve(response.recordsets[0]))
      .catch((error) => reject(error));
  });
}

export async function getEmployeesByToken(nameToken) {
  return new Promise((resolve, reject) => {
    fetch(getEmployeesByTokenURL + nameToken)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        return resolve(response.recordsets[0]);
      })
      .catch((error) => reject(error));
  });
}

export async function loadMigrationLog(ntname) {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_MIGRATION_TRACKER_ENDPOINT}/getMigrationLogs/${ntname}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('accesstoken'),
        },
      }
    )
      .then((response) => response.json())
      .then((response) =>
        resolve({
          data: response.body.recordSets,
          role: response.body.role,
        })
      )
      .catch((error) => {
        if (Number(localStorage.getItem('expires_at')) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}
export async function getCognitoUser() {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_MIGRATION_TRACKER_ENDPOINT}/getUsername`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('accesstoken'),
      },
    })
      .then((response) => response.json())
      .then((response) =>
        resolve({
          user: response.body.username.split('_')[1],
        })
      )
      .catch((error) => {
        if (Number(localStorage.getItem('expires_at')) < new Date().getTime()) {
          window.location.href = clientIdForSignUp;
        }
        return reject(error);
      });
  });
}

export async function getEmployeeObjectByNtname(ntName) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_PROD_URL}:8899/GetEmployeeObjectByNtname/${ntName}`
      )
      .then((response) => {
        console.log('NT Name - ', response.data);
        return resolve(response.data); // .recordsets[0]);
      })
      .catch((error) => {
        console.log(error);
        return reject(error);
      });
  });
}
