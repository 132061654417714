import * as React from 'react';
import { GridColumnMenuCheckboxFilter } from '@progress/kendo-react-grid';

export class ColumnMenu extends React.Component {
  render() {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...this.props}
          data={this.props.data}
          expanded={true}
          searchBox={() => null}
        />
      </div>
    );
  }
}
