import React, { useState, useEffect, Fragment } from 'react';
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridColumnMenuFilter,
} from '@progress/kendo-react-grid';
import { process, State } from '@progress/kendo-data-query';
import { Tooltip } from '@progress/kendo-react-tooltip';
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';
import { Loader } from '@progress/kendo-react-indicators';
import { MigrationLog } from './migrationLogProps';
import MigrationLogForm from './MigrationLogForm';
import {
  migrationStatus,
  yesNo,
  departmentTypes,
  approvalLevels,
  priorityLevels,
  findObjectByValue,
} from '../staticOptions';
import '@progress/kendo-theme-material/dist/all.css';
import './MigrationLogs.css';
import { ColumnMenu } from './ColumnMenu.jsx';
import {
  createMigrationLog,
  updateMigrationLog,
} from '../services/postServicesPromise';
import {
  loadMigrationLog,
  getCognitoUser,
} from '../services/getServicesPromise';

const hasEditPermission = (userRole: string) => {
  const editPermissionRoles = 'ADMIN';
  // let recordCreatedByCurrentUser = currentUserNTName == CreatedByNTName;
  // return editPermissionRoles == role || recordCreatedByCurrentUser;
  return userRole === editPermissionRoles;
};

const ViewEditCommand = (props: any) => {
  const editPermission = hasEditPermission(props.permissionLevel);
  const labelText = editPermission ? 'Edit' : 'View';
  if (props.rowType !== 'groupHeader') {
    return (
      <td>
        <button
          className='k-button k-primary'
          onClick={() =>
            props.enterEdit({
              ...props.dataItem,
              readonly: !editPermission,
            })
          }
        >
          {labelText}
        </button>
      </td>
    );
  }
  return null;
};

const TooltipContentTemplate = (props: any) => {
  const values = props.title.split('#');

  return (
    <div className='tooltipcontent'>
      <div>
        <strong>Name: </strong>
        {values[0]}
      </div>
      <div>
        <strong>System Owner: </strong>
        {values[1]}
      </div>
      <div>
        <strong>Comments: </strong>
        {values[2]}
      </div>
    </div>
  );
};

const datastate = {
  skip: 0,
  take: 100,
};

export default class MigrationLogger extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    // SKA TODOs per feedback

    this.state = {
      loading: false,
      migrationLogData: [],
      employeeData: [],
      visibleDialog: false,
      openForm: false,
      openNewForm: false,
      editItem: {},
      allowUpdate: false,
      skip: 0,
      take: 30,
      buttoncount: 20,
      dataState: datastate,
      result: [],
      dataPrevResult: [],
      Status: migrationStatus,
      DepartmentTypes: departmentTypes,
      formMode: undefined,
      currentUserNTName: undefined,
      permissionLevel: undefined,

      // todo
      approvalLevel: approvalLevels,
      SysProcCheck: yesNo,
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public componentDidMount() {
    try {
      this.loadMigrationLogData();
    } catch (e) {
      console.error('error:', e);
    }
  }

  public async loadMigrationLogData() {
    try {
      const cognitoUser = await getCognitoUser();
      const currentdata = await loadMigrationLog(cognitoUser.user);
      const mapIssExternal = currentdata.data.map((item: MigrationLog) => {
        const ynsp = item.SystemsAndProcess ? 'Yes' : 'No';
        item.SystemsAndProcess = Boolean(item.SystemsAndProcess);
        if (item.MigrationTargetDate) {
          item.MigrationTargetDate = new Date(
            item.MigrationTargetDate.replace(/-/g, '/').replace(/T.+Z/, '')
          );
        }
        // Does not match, so return the counter without changing.
        if (item.SystemID !== null || item.DeptID !== null) {
          return {
            ...item,
            IsExternal: 'Yes',
            isSystemsProcess: ynsp,
            Priority: findObjectByValue(priorityLevels, 'value', item.Priority),
          };
        }
        // Else (means match) return a new counter but change only the value

        return {
          ...item,
          IsExternal: 'No',
          isSystemsProcess: ynsp,
          Priority: findObjectByValue(priorityLevels, 'value', item.Priority),
        };
      });

      console.log('mapIssExternal -- ', mapIssExternal);

      // SKA
      this.setState(
        {
          migrationLogData: mapIssExternal, // mapEmployeeData
          loading: true,
          currentUserNTName: cognitoUser.user,
          permissionLevel: currentdata.role,
        },
        () => {
          this.setState(this.createDataState(this.state.dataState));
        }
      );
    } catch (e: any) {
      console.log(this.props.redirectUrl);
      console.log('error:', e);
      window.location.href = this.props.redirectUrl;
    }
  }

  enterEdit = (item: MigrationLog) => {
    this.setState({
      openForm: true,
      editItem: item,
      formMode: 'edit',
    });
  };

  enterNewForm = (item: any) => {
    this.setState({
      openNewForm: true,
      editItem: {},
      formMode: 'new',
    });
  };

  EditCommand = (props: any, currentUserNTName: string) => {
    console.log('EDIT COMMAND PROPS', props);
    return (
      <ViewEditCommand
        {...{
          ...props,
          currentUserNTName,
          permissionLevel: this.state.permissionLevel,
        }}
        enterEdit={this.enterEdit}
      />
    );
  };

  handleCancelEdit = () => {
    console.log('handleCancelEdit');
    if (this.state.openForm === true) {
      this.setState({ openForm: false });
    }
    if (this.state.openNewForm === true) {
      this.setState({ openNewForm: false });
    }
  };

  handleSubmit = async (event: any, nativeEvent: any) => {
    try {
      nativeEvent.preventDefault();
      event.Priority = event.Priority.value;
      // CHANGE B4 PUSH
      const cognitoUser = await getCognitoUser();
      event = {
        ...event,
        userNTName: cognitoUser.user,
        userFullName: cognitoUser.user,
      };
      Object.keys(event).map((key) => {
        if (event[key] === '') event[key] = undefined;
      });
      console.log('event:', event);
      switch (this.state.formMode) {
        case 'new':
          console.log('calling handleCreate');
          this.handleCreate(event);
          break;
        case 'edit':
          console.log('calling handleUpdate');
          this.handleUpdate(event);
          break;
        default:
          break;
      }
    } catch (err) {
      // @ts-ignore
      const errObj = { message: err?.message, url: err?.config?.url };
      alert(
        `Error Submitting Form, contact admins at SystemsDevelopment@gilead.com : ${JSON.stringify(
          errObj,
          null,
          2
        )}`
      );
    }
  };

  handleUpdate = async (event: any) => {
    console.log('Update Migration Log!!', `${event}${this.state.editItem}`);
    let postObject: any = {};
    const cognitoUser = await getCognitoUser();
    postObject = {
      ...event,
      id: event.MigrationID,
      ModifiedOn: new Date(),
      ModifiedByNTName: cognitoUser.user,
      ModifiedBy: cognitoUser.user,
    };
    updateMigrationLog(postObject).then(() => {
      this.loadMigrationLogData();
    });
    this.setState({
      openForm: false,
    });
  };

  handleCreate = async (event: any) => {
    let postObject: any = {};
    console.log('handleCreate event', event);
    const cognitoUser = await getCognitoUser();
    postObject = {
      ...event,
      Status: 'New/Evaluating',
      CreatedByNTName: cognitoUser.user,
      CreatedBy: cognitoUser.user,
      CreatedOn: new Date(),
    };
    console.log('Create Migration Log!!', postObject);
    createMigrationLog(postObject).then(() => {
      this.loadMigrationLogData();
    });
    this.setState({ openNewForm: false });
  };

  pageChange = (event: any) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  dataStateChange = (event: any) => {
    this.setState(this.createDataState(event.dataState));
  };

  createDataState = (dataState: State) => ({
    result: process(this.state.migrationLogData.slice(0), dataState),
    dataState,
  });

  colMenu = (props: any) => (
    <ColumnMenu {...props} data={this.state.result.data} />
  );

  _export: any;

  export = () => {
    this._export.save();
  };

  render() {
    if (!this.state.loading) {
      return <Loader />;
    }
    return (
      <div>
        <div className='app_top_section'>
          <div className='app_title'>Migration Tracker</div>

          <div className='app_description'>
            List of Development Operations systems that are being migrated from
            Sharepoint on-prem to SharePoint online, and on-prem servers and
            apps to AWS.
          </div>
        </div>

        <div className='migrationLog_grid'>
          <ExcelExport
            data={this.state.migrationLogData}
            ref={(exporter) => (this._export = exporter)}
          >
            <ExcelExportColumn field='MigrationID' title='MigrationID' />
            <ExcelExportColumn field='SystemID' title='SystemID' />
            <ExcelExportColumn field='DeptID' title='DeptID' />
            <ExcelExportColumn field='PBIID' title='PBIID' />
            <ExcelExportColumn
              field='SystemsAndProcess'
              title='SystemsAndProcess'
            />
            <ExcelExportColumn field='SystemName' title='SystemName' />
            <ExcelExportColumn field='SystemOwner' title='SystemOwner' />
            <ExcelExportColumn field='MigrationOwner' title='MigrationOwner' />
            <ExcelExportColumn
              field='BusinessFunction'
              title='BusinessFunction'
            />
            <ExcelExportColumn field='OldPlatform' title='OldPlatform' />
            <ExcelExportColumn field='NewPlatform' title='NewPlatform' />
            <ExcelExportColumn
              field='MigrationTargetDate'
              title='MigrationTargetDate'
            />
            <ExcelExportColumn field='SparcUrl' title='SparcUrl' />
            <ExcelExportColumn field='PBIUrl' title='PBIUrl' />
            <ExcelExportColumn field='Priority.label' title='Priority' />
            <ExcelExportColumn field='Priority.value' title='Priority Value' />
            <ExcelExportColumn field='Status' title='Status' />
            <ExcelExportColumn field='Comments' title='Comments' />
          </ExcelExport>

          <Tooltip
            openDelay={100}
            position='right'
            content={(props) => <TooltipContentTemplate title={props.title} />}
          >
            <Grid
              style={{
                maxHeight: '800px',
                minHeight: '800px',
              }}
              data={this.state.result}
              resizable
              sortable
              filterable
              groupable
              pageable
              total={this.state.result.length}
              {...this.state.dataState}
              onDataStateChange={this.dataStateChange}
            >
              <GridToolbar>
                <div className='migrationLog_grid_GridToolbar'>
                  {hasEditPermission(this.state.permissionLevel) && (
                    <div className='div_addnew'>
                      <button
                        className='k-button k-primary '
                        onClick={this.enterNewForm}
                        value='0'
                      >
                        {' '}
                        Create a New Migration Tracker
                      </button>
                    </div>
                  )}

                  <div
                    className='migrationLog_grid_export'
                    style={{ float: 'right' }}
                  >
                    <button
                      className='k-button k-primary'
                      onClick={this.export}
                    >
                      {' '}
                      Export to Excel
                    </button>
                  </div>
                </div>
              </GridToolbar>

              <Column field='SystemName' title='System Name' />
              <Column field='MigrationOwner' title='Migration Owner' />
              <Column field='BusinessFunction' title='Business Function' />
              <Column
                field='Status'
                title='Status'
                width={150}
                filterable={false}
                columnMenu={this.colMenu}
              />
              <Column
                field='MigrationTargetDate'
                filter='date'
                format='{0:MM/dd/yyyy}'
                title='Migration Target Date'
              />
              <Column field='OldPlatform' title='Old Platform' />
              <Column field='NewPlatform' title='New Platform' />
              <Column
                cell={(e) => this.EditCommand(e, this.state.currentUserNTName)}
                filterable={false}
                width={110}
                groupable={false}
              />
            </Grid>
          </Tooltip>
        </div>

        {(this.state.openNewForm || this.state.openForm) && (
          <MigrationLogForm
            cancelEdit={this.handleCancelEdit}
            onSubmit={this.handleSubmit}
            dataVisible={this.state.Visible}
            visibleVendor={this.state.vendorVisible}
            mode={this.state.formMode}
            item={this.state.editItem}
          />
        )}
      </div>
    );
  }
}
